.darkmode {
  --p-color: white;
  /* --img-about:url(../Images/Screenshot\ \(121\).png) no-repeat center; */
}

.lightmode {
  --p-color: rgb(0, 0, 0);
  /* --img-about:nill; */
}

.allProjectsP p {
  color: transparent;
  background: linear-gradient(
    to right,
    rgb(182, 129, 49),
    rgb(246, 221, 122),
    rgb(160, 115, 47)
  );
  /* -webkit-text-stroke: 1px rgb(201, 157, 69); */
  font-size: 4rem;
  font-family: "Raleway", sans-serif;
  background-clip: content-box;
  -webkit-background-clip: text;
  margin-bottom: 20px;
  margin-left: 2rem;
  /* border: 2px solid red; */
  width: fit-content;
  /* margin-top: 2rem; */
}

.singleProject {
  display: flex;
  border: 1px solid rgb(169, 124, 82);
  border-radius: 10px;
  margin: 2rem 2rem 0 2rem;
  text-decoration: none;
  transition: all linear 0.2s;
}
.singleProject:hover {
  /* transform: scale(1.); */
  box-shadow: 0px 0px 10px 0px rgb(242, 183, 128);
}
.projectImg {
  width: 20rem;
  height: 10rem;
  border-radius: 10px;
  /* border: 2px solid red; */
  /* height: 100px; */
}
.projectDesc {
  color: var(--p-color);
  margin: 0px 10px 0px 10px;
  align-self: center;
  font-size: 2rem;
}

@media (max-width: 1111px) {
  .allProjectsP p {
    font-size: 3rem;
  }
  .projectDesc {
    font-size: 1.5rem;
  }
  /* .projectImg {
    width: 15vw;
    height: 10vh;
  } */
}
@media (max-width: 810px) {
  .allProjectsP p {
    font-size: 2rem;
  }
}
@media (max-width: 600px) {
  .allProjectsP p {
    font-size: 2rem;
    margin-left: 1rem;
    max-width: 80%;
  }
  .projectDesc {
    font-size: 1rem;
  }
  .projectImg {
    width: 12rem;
    height: 6rem;
    /* height: 100px; */
  }
  .singleProject {
    margin: 1rem 1rem 0px 1rem;

    /* display: grid; */
    /* place-items: center; */
  }
  /* .allPCont {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } */
}

@media (max-width: 420px) {
  .allProjectsP p {
    font-size: 1.5rem;
  }
  .projectImg {
    width: 40vw;
    height: 10vh;
    /* height: 100px; */
  }
}
