#body{
  z-index: 100;
}

.navBg{
  display: none;
  position: fixed;
  top: 0;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  opacity: .5;
}

.navBg.active{
  display: block;
}

.header {
  background-color: rgb(233, 83, 83);
  /* background-color: red; */
  height: 100%;
  /* top: 0; */
  transition: all linear 0.2s;
  z-index: 98;
  position: fixed;
  right: -12rem;
  top: 0;
  overflow: hidden;
}

.header.active {
  right: 0rem;
}

.header ul {
  padding: 5rem 0;
  display: block;
  margin: 0 2rem;
}

.toggle {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  position: relative;
  width: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;

  top: 6rem;
  left: 2.5rem;
  cursor: pointer;
  padding: 4px 5px;
}

.fa-sun,
.fa-moon {
  /* margin-right: 1rem; */
  /* margin-top: 1rem; */
  /* text-align: center; */
  /* font-size: 1.5rem; */
  /* border: 2px solid red; */
  color: rgb(240, 188, 45);
  /* border: 2px solid rgb(255, 0, 0); */
  /* color: rgb(218, 87, 87); */
  /* padding: 5px; */
  /* border-radius: 100px; */
  /* transition: all linear .3s; */
  /* border: 2px solid white; */
}
.fa-moon {
  position: relative;
  /* top: 0.3rem; */
  /* left: 0.5rem; */
}
.fa-sun {
  position: relative;
  /* left: 2.7rem; */
  /* top: 0.3rem; */
}

.dot {
  transition: all linear 0.2s;
  /* transform: scale(1.7); */
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-.25rem);
  /* left: 0.1rem; */
  height: 36px;
  width: 36px;
  border-radius: 100%;
  /* background-color: rgb(30, 30, 30); */
  background-color: rgb(233, 83, 83);
}

.dot.active {
  /* left: 3.5rem;. */
  transform: translateY(-50%) translateX(3.1rem);
}

.allLists {
  /* border: 2px solid red; */
  text-decoration: none;
  /* list-style: none; */
  /* background: none; */
  margin: 3rem 0vh;
  text-align: center;
}

.allLinks {
  transition: all linear 0.2s;
  font-family: "Arimo", sans-serif;
  font-weight: 600;
  margin-left: 0vw;
  font-size: 1.4rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(233, 83, 83);
  margin: 40px 0px;
}

.allLinks:hover {
  border-bottom: 2px solid rgb(255, 255, 255);
}

#tog {
  z-index: 999;
  /* font-size: 2rem; */
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  color: rgb(233, 83, 83);
  transition: all linear 0.3s;
}

#tog.active {
  color: white;
}

/* #tog:hover {
  color: white;
} */
