.body {
  
  height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
}

.body::before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: var(--op);
  background:var(--bg-img);
  background-size: cover;
}

.framer {
  z-index: 111;
}

.darkmode{
--transition:url(../Images/JK.png) no-repeat center;
--bg-img: url(../Images/background-5035258_1920.jpg)  no-repeat center;
--op:0.4;
--pp-color:rgb(255, 255, 255);
--name-color:rgb(230, 185, 38);
}

.lightmode{
  --transition:url(../Images/light\ mode\ transition.png) no-repeat center;
  --bg-img: url(../Images/white-bg.jpg)  no-repeat center; 
  --op:1;
  --pp-color:rgb(0, 0, 0);
  /* --name-color:rgb(234, 197, 111); */
  --name-color:rgb(135, 105, 5);

}


.transition {
  z-index: 111;
  height: 100vh;
  width: 100vw;
  background: var(--transition);
  background-size: cover;
}




.myVideo {
  border: 2px solid rgb(46, 45, 45);
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -91;
}

.hi {
  font-family: "Rubik", sans-serif;
  font-weight: 100;
  text-align: center;
  font-size: 40px;
  color: var(--pp-color);
}
.welcome {
  position: absolute;
  bottom: 0;
  font-family: "Rubik", sans-serif;
  text-align: center;
  font-size: 20px;
  color: var(--pp-color);
  z-index: 99;
}

.heading{
  position: relative;
  /* top: 100px; */
  /* top: 50px; */
}

.heading h1 {
  color: var(--name-color);

  font-size: 70px;
}

#myname {
  font-size: 8rem;
  font-weight: 300;
  font-family: "Grey Qo", cursive;
  position: relative;
}

#modelCont{
  z-index: 100;
  position: relative;
  top: -12rem;
  /* top: -12rem; */
  /* border: 5px solid red; */
  /* width: 400px; */
  /* position: absolute; */
  /* top: 0; */
}

.arrow {
  color: rgb(233, 83, 83);
  /* border: 2px solid red; */
  cursor: pointer;
  transform: scale(1.5);
  position: absolute;
  animation: arrow linear infinite 1.5s;
}

.anchor {
  position: absolute;
  top: 0vh;
}

@keyframes arrow {
  0% {
    top: 90vh;
  }
  50% {
    top: 92vh;
  }
  100% {
    top: 90vh;
  }
}

#upArrow {
  position: absolute;
}

.fa-circle-chevron-up {
  z-index: 99;
  /* font-size: 2rem; */
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  cursor: pointer;
  color: rgb(233, 83, 83);
  transition: all linear 0.3s;
}

/* .fa-circle-chevron-up:hover {
  color: white;
} */

@media (max-width: 426px) {
  /* .fa-circle-chevron-up {
    font-size: 7vw;
  } */
  .hi {
    font-size: 10vw;
  }
  #myname {
    font-size: 25vw;
  }
  .welcome {
    font-size: 4vw;
  }
}