.contactBody {
  height: 100vh;
  color: var(--p-color);
  overflow: hidden;
  background: var(--img);
  background-size: cover;
  display: grid;
  place-items: center;
}


.darkmode{
  --p-color:white;
 --img:url(../Images/Screenshot\ \(122\).png) no-repeat center;
 --inp-color:rgb(30, 30, 30);
}
.lightmode{
  --p-color:rgb(0, 0, 0);
  --img:55;
  --inp-color:rgb(255, 255, 255);
  
  }


.bgContact p {
  color: rgb(227, 162, 63);
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
}

.userName {
  margin-bottom: 5px;
}

.userEmail {
  margin-bottom: 20px;
}

#userNameinp,
#userEmailinp,
#userTextinp {
  background-color: var(--inp-color);
  border: transparent;
  border-bottom: 2px solid rgb(233, 83, 83);
  padding: 5px;
  width: 30rem;
  color: var(--p-color);
}

#userTextinp {
  border: 2px solid rgb(233, 83, 83);
  height: 20rem;
}

#submit {
  font-size: 1.1rem;
  color: var(--p-color);
  padding: 5px 15px;
  background-color: var(--inp-color);
  border: 2px solid rgb(239, 156, 54);
  box-shadow: inset 0px 0px 0px 0px rgb(229, 148, 56);
  transition: all 0.2s linear;
  cursor: pointer;
}

#submit:hover {
  box-shadow: inset 12rem 0px 0px 0px rgb(229, 148, 56);
}

.suceed {
  height: 100vh;
  display: grid;
  place-items: center;
  color: var(--p-color);
  margin: 0;
  padding: 0;
  text-align: center;
}

.suceed button {
  font-size: 1.1rem;
  color: var(--p-color);
  padding: 5px 15px;
  background-color: var(--inp-color);
  border: 2px solid rgb(239, 156, 54);
  box-shadow: inset 0px 0px 0px 0px #e59438;
  transition: all 0.2s linear;
  cursor: pointer;
}

.suceed button:hover {
  box-shadow: inset 12rem 0px 0px 0px rgb(229, 148, 56);
}

@media (max-width: 700px) {
  .bgContact p {
    font-size: 8vw;
  }

  #userTextinp {
    /* height: 20; */
    width: 80vw;
  }

  #userNameinp,
  #userEmailinp,
  #userTextinp {
    width: 80vw;
  }

  #submit {
    font-size: 3.3vw;
  }
}
